@use "@angular/material" as mat;
@use 'theme/theme.scss' as my-theme;

$mdc-layout-grid-default-margin: (
  desktop: 0px,
  tablet: 0px,
  phone: 0px
);

@import "@material/layout-grid/mdc-layout-grid";

@include mat.core();

@include mat.typography-hierarchy(my-theme.$erki-typography);

@include mat.core-theme(my-theme.$my-app-theme);
@include mat.snack-bar-theme(my-theme.$my-app-theme);
@include mat.toolbar-theme(my-theme.$my-app-theme);
@include mat.icon-theme(my-theme.$my-app-theme);
@include mat.menu-theme(my-theme.$my-app-theme);
@include mat.button-theme(my-theme.$my-app-theme);
@include mat.grid-list-theme(my-theme.$my-app-theme);
@include mat.divider-theme(my-theme.$my-app-theme);
@include mat.badge-theme(my-theme.$my-app-theme);
@include mat.bottom-sheet-theme(my-theme.$my-app-theme);
@include mat.dialog-theme(my-theme.$my-app-theme);
@include mat.sidenav-theme(my-theme.$my-app-theme);
@include mat.list-theme(my-theme.$my-app-theme);
@include mat.input-theme(my-theme.$my-app-theme);
@include mat.progress-spinner-theme(my-theme.$my-app-theme);
@include mat.form-field-theme(my-theme.$my-app-theme);
@include mat.fab-theme(my-theme.$my-app-theme);
@include mat.stepper-theme(my-theme.$my-app-theme);
@include mat.radio-theme(my-theme.$my-app-theme);
@include mat.slide-toggle-theme(my-theme.$my-app-theme);
@include mat.card-theme(my-theme.$my-app-theme);
@include mat.table-theme(my-theme.$my-app-theme);
@include mat.select-theme(my-theme.$my-app-theme);
@include mat.chips-theme(my-theme.$my-app-theme);
@include mat.tooltip-theme(my-theme.$my-app-theme);
@include mat.autocomplete-theme(my-theme.$my-app-theme);
@include mat.expansion-theme(my-theme.$my-app-theme);

.mat-headline-2 {
  margin-bottom: 32px !important;
}

.mat-headline-3 {
  margin-bottom: 32px !important;
}

.mat-headline-4 {
  margin-bottom: 32px !important;
}

body { 
  margin: 0; 
  min-width: 360px;
}

.material-page {
    
  margin-left: auto;
  margin-right: auto;

  @media (max-width: 599px) {
    margin-left: 16px;
    margin-right: 16px;
    padding-right: 0;
    width: auto;
  }

  @media (min-width: 600px) and (max-width: 904px) {
    margin-left: 32px;
    margin-right: 32px;
    padding-right: 0;
    width: auto;
  }
  
  @media (min-width: 905px) and (max-width: 1007px) {
    margin-left: auto;
    margin-right: auto;
    padding-right: 0;
    width: 840px;
  }
  
  @media (min-width: 1008px) and (max-width: 1191px) {
    margin-left: 0;
    margin-right: auto;
    padding-right: 0;
    width: 840px;
  }
  
  @media (min-width: 1192px) and (max-width: 1239px) {
    margin-left: 0;
    margin-right: auto;
    padding-right: 0;
    width: 840px;
  }
  
  @media (min-width: 1240px) and (max-width: 1439px) {
    margin-left: 0;
    margin-right: 200px;
    padding-right: 0;
    width: auto;
  }
  
  @media (min-width: 1440px) and (max-width: 1647px) {
    margin-left: 0;
    margin-right: auto;
    padding-right: 0;
    width: 1040px;
  }
  
  @media (min-width: 1648px) {
    margin-left: auto;
    margin-right: auto;
    padding-right: 256px;
    width: 1040px;
  }
}

.with-stripe {
  background: linear-gradient(to bottom, white 0 35%,#cafa40 35% 65%,white 65% 100%);
}

.mdc-button__label {
  white-space: nowrap; 
  text-transform: uppercase;
  min-width: auto;
  
  mat-spinner {
    display: inline-block;
    position: relative;
    vertical-align: middle;
    margin-left: -4px;
    margin-right: 8px;
    font-size: 1.125rem;
  }
}

.button-panel {
  margin-top: 32px;
  
  button {
    margin-right: 8px;
  }
  
  @media only screen and (max-width: map-get($mdc-layout-grid-breakpoints, 'tablet')) {
    button {
      width: 100%;
      margin-bottom: 8px;
    }
  }
}

.mat-sidenav-container {
  min-height: calc(100vh); 
}

.vertical-button-panel {
  
  
  button {
    width: 100%;
    margin-bottom: 8px;
  }
  
  @media only screen and (min-width: map-get($mdc-layout-grid-breakpoints, 'tablet')) and (max-width: map-get($mdc-layout-grid-breakpoints, 'desktop')) {
    button {
      width: auto;
      margin-right: 8px;
      margin-bottom: 0px;
    }
  }
}

a:hover {
  cursor: pointer;
}

a {
  color: black;
  text-decoration: none;
}

p a, a.mid-text {
  text-decoration: underline;
}

a:hover {
  text-decoration: underline;
}

.filled-icon {
  font-variation-settings: 'FILL' 1
}

.with-spinner {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  
  mat-spinner {
    margin-right: 8px;
  }
}

.mdc-list-item--with-leading-icon .mdc-list-item__start {
  margin-right: 24px !important;
}

mat-form-field {
  width: 100%;
  max-width: 500px;
}

body[angular-loading] button {
  pointer-events: none;
  opacity: 0.5;
  cursor: wait;
}

button.mat-mdc-raised-button {
  transition: none !important;
}